import Const from '../constants'
import axiosApiInstance from '../interceptors'

class DeleteMemberService {
  deleteMember() {
    return axiosApiInstance.put(Const.api_url + 'member/deactive', {
    })
  }
}

export default new DeleteMemberService()
