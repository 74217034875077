














































































































import { Component, Vue } from 'vue-property-decorator'
import LayoutCompact from '@/layouts/LayoutCompact.vue'
import DeleteMemberService from '@/services/DeleteMemberService'
import { namespace } from 'vuex-class'
const Auth = namespace('Auth')

@Component({
  components: {
    LayoutCompact
  }
})
export default class WithdrawProcedure extends Vue {
  public msg: boolean = false
  @Auth.Action
  public signOut!: () => void

  public deleteMember() {
    DeleteMemberService.deleteMember().then((response: any) => {
      if (response) {
        localStorage.clear()
        this.$router.push({ name: 'home' })
      }
    },(error:any) => {
      if (error.response.status === 429) {
        localStorage.clear()
        this.$bvModal.show(`too-many-request`)
      }
      if (error.response.status === 500) {
        localStorage.clear()
        if (
            error.response.data.message[0] ===
            'CANNOT_CANCEL_PAYMENT_FOR_ORDER_OF_USER'
        ) {
          this.$bvModal.show(`cancel-order-user-fail`)
        } else if (
            error.response.data.message[0] ===
            'CANNOT_DELETE_CREDIT_CARD'
        ) {
          this.$bvModal.show(`delete-user-credit-card-fail`)
        } else {
          this.$bvModal.show(`delete-user-fail`)
        }
      }
    })
  }

  public goToMemberInfo() {
    this.$router.push({ name: 'membership-information' })
  }
}
